import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import JoinUs from 'components/JoinUs';

const BlogSinglePage = () => {
  return (
    <Layout>
      <SEO
        title="Join Us! - CloudBest"
        description="Work with some of the most talented and skilled experts on ambitious global projects and innovative technology that disrupts the industry. "
      />
      <JoinUs />
    </Layout>
  );
};

export default BlogSinglePage;
