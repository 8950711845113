import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Button } from 'components/JoinUsButton/styled';
import {
  Wrapper,
  GradientTitle,
  ButtonWrapper,
  JoinUsDescription,
} from './styled';

const OUR_VACANCIES_URL =
  'https://www.praca.pl/s-cloud,best,sp,z,o,o.html?p=Cloud+Best+Sp.+z+o.+o.%2C+';

const JoinUs = () => {
  const {
    pageTitle,
    title,
    subtitle,
    description,
    ourVacancies,
  } = useJoinUsContent();

  return (
    <Wrapper>
      <GradientTitle>{pageTitle}</GradientTitle>
      <article>
        <h2>{title}</h2>
        <h3>{subtitle}</h3>
        <JoinUsDescription dangerouslySetInnerHTML={{ __html: description }} />
        <ButtonWrapper>
          <Button as="a" href={OUR_VACANCIES_URL}>
            {ourVacancies}
          </Button>
        </ButtonWrapper>
      </article>
    </Wrapper>
  );
};

export default JoinUs;

function useJoinUsContent() {
  const {
    joinUs: {
      pageContent: { pageTitle, description, subtitle, title, ourVacancies },
    },
  } = useStaticQuery(
    graphql`
      query {
        joinUs: wpPage(title: { in: "Join us" }) {
          pageContent {
            pageTitle
            description
            subtitle
            title
            ourVacancies
          }
        }
      }
    `,
  );

  return { pageTitle, description, subtitle, title, ourVacancies };
}
