import styled from 'styles/styled';
import { gradientTextStyles } from 'components/ui/Heading';
import Paragraph from 'components/ui/Paragraph';

export const Wrapper = styled.section`
  position: relative;
  z-index: 1;
  width: 100%;
  display: grid;
  margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10 * 10}px;

  h2 {
    margin-bottom: 32px;
    font-weight: ${({ theme }) => theme.fontWeight.bold};

    @media ${({ theme }) => theme.devices.medium} {
      margin-bottom: 0;
    }
  }

  h3 {
    margin-top: -24px;
    margin-bottom: 15px;
  }

  p {
    margin-top: ${({ theme }) => theme.dimensions.spacingBase10 * 2}px;
    line-height: 18px;
  }

  @media ${({ theme }) => theme.devices.medium} {
    h3 {
      margin-top: 0;
    }
  }

  @media ${({ theme }) => theme.devices.large} {
    margin-top: 250px;
    align-items: center;
    height: 100%;
    padding-top: ${({ theme }) => theme.dimensions.spacingBase10}px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    h3 {
      margin-top: 0;
      margin-bottom: 22px;
    }

    h2 {
      margin-top: 0;
    }
  }
`;

export const GradientTitle = styled.h2`
  ${gradientTextStyles};
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-align: center;
  margin-top: ${({ theme }) => theme.dimensions.spacingBase10 * 7}px;
  letter-spacing: 1.26px;

  @media ${({ theme }) => theme.devices.large} {
    text-align: left;
    font-size: ${({ theme }) => theme.fontSize.pageTitle};
    margin-top: 0;
    padding-bottom: 45px;
  }
`;

export const StyledLink = styled.a`
  transition: ${({ theme }) => theme.ui.transition('opacity')};
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  &:hover {
    opacity: ${({ theme }) => theme.ui.opacity()};
  }

  @media ${({ theme }) => theme.devices.medium} {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 29px;
  justify-content: center;

  @media ${({ theme }) => theme.devices.medium} {
    justify-content: flex-start;
  }
`;

export const JoinUsDescription = styled(Paragraph)`
  a {
    transition: ${({ theme }) => theme.ui.transition('opacity')};
    font-weight: ${({ theme }) => theme.fontWeight.regular};

    &:hover {
      opacity: ${({ theme }) => theme.ui.opacity()};
    }

    @media ${({ theme }) => theme.devices.medium} {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    }
  }
`;
