import styled from 'styles/styled';

const Paragraph = styled.p`
  color: ${({ theme }) => theme.palette.lightDark};
  font-size: ${({ theme }) => theme.fontSize.baseMobile};
  letter-spacing: 0.1px;
  line-height: 18px;

  @media ${({ theme }) => theme.devices.medium} {
    font-size: ${({ theme }) => theme.fontSize.base};
    letter-spacing: 0.14px;
    line-height: 22px;
  }
`;

export const WhiteParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.palette.light};
`;

export default Paragraph;
